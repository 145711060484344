import { BaseModel, PaginatedResponse } from 'src/app/models/utils';

export interface Notification extends Partial<BaseModel> {
  userId: string;
  companyId: string;
  title: string;
  body: string;
  url: string;
  hasRead: boolean;
  isOnlyForRecipient: boolean;
  origin: NotificationOrigin;
}

export type PaginatedNotifications = PaginatedResponse<Notification[]>;

export const NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY =
  'notificationPermission';

export const NOTIFICATION_PERMISSION_DEFAULT = 'default';
export const NOTIFICATION_PERMISSION_GRANTED = 'granted';
export const NOTIFICATION_PERMISSION_DENIED = 'denied';

export interface NotificationOrigin {
  type: NotificationOriginType;
  name: NotificationOriginName;
}

export enum NotificationOriginType {
  ORD = 'ORD',
  DOC = 'DOC',
  MM = 'MM',
  ADMIN = 'ADMIN',
}

export enum NotificationOriginName {
  ENB_INQUIRY_MATCH = 'ENB_INQUIRY_MATCH',
  AEZ_NEW_OFFER = 'AEZ_NEW_OFFER',
  AEZ_ENB_REQUESTED_INFORMATION = 'AEZ_ENB_REQUESTED_INFORMATION',
  ENB_AEZ_PROVIDED_INFORMATION = 'ENB_AEZ_PROVIDED_INFORMATION',
  ENB_AEZ_ACCEPTED_OFFER = 'ENB_AEZ_ACCEPTED_OFFER',
  ENB_AEZ_TRIGGERED_PICKUP = 'ENB_AEZ_TRIGGERED_PICKUP',
  AEZ_ENB_SCHEDULED_PICKUP = 'AEZ_ENB_SCHEDULED_PICKUP',
  ENB_DAY_OF_PICKUP = 'ENB_DAY_OF_PICKUP',
  AEZ_ENB_CONFIRMED_PICKUP = 'AEZ_ENB_CONFIRMED_PICKUP',
  AEZ_ENB_RESCHEDULED_PICKUP = 'AEZ_ENB_RESCHEDULED_PICKUP',
  ENB_REMINDER_TO_UPLOAD_DE_NT = 'ENB_REMINDER_TO_UPLOAD_DE_NT',
  ENB_REMINDER_TO_UPLOAD_SU_NT = 'ENB_REMINDER_TO_UPLOAD_SU_NT',
  AEZ_ENB_HAS_UPLOADED_DE_NT = 'AEZ_ENB_HAS_UPLOADED_DE_NT',
  AEZ_ENB_HAS_UPLOADED_SU_NT = 'AEZ_ENB_HAS_UPLOADED_SU_NT',
  ENB_REMINDER_TO_UPLOAD_WE_NT = 'ENB_REMINDER_TO_UPLOAD_WE_NT',
  AEZ_ENB_HAS_UPLOADED_WE_NT = 'AEZ_ENB_HAS_UPLOADED_WE_NT',
  ENB_REMINDER_TO_UPLOAD_INV = 'ENB_REMINDER_TO_UPLOAD_INV',
  AEZ_ENB_HAS_UPLOADED_INV = 'AEZ_ENB_HAS_UPLOADED_INV',
}

export function fromString(type: string): NotificationOriginName | null {
  switch (type) {
    case 'ENB_INQUIRY_MATCH':
      return NotificationOriginName.ENB_INQUIRY_MATCH;
    case 'AEZ_NEW_OFFER':
      return NotificationOriginName.AEZ_NEW_OFFER;
    case 'AEZ_ENB_REQUESTED_INFORMATION':
      return NotificationOriginName.AEZ_ENB_REQUESTED_INFORMATION;
    case 'ENB_AEZ_PROVIDED_INFORMATION':
      return NotificationOriginName.ENB_AEZ_PROVIDED_INFORMATION;
    case 'ENB_AEZ_ACCEPTED_OFFER':
      return NotificationOriginName.ENB_AEZ_ACCEPTED_OFFER;
    case 'ENB_AEZ_TRIGGERED_PICKUP':
      return NotificationOriginName.ENB_AEZ_TRIGGERED_PICKUP;
    case 'AEZ_ENB_SCHEDULED_PICKUP':
      return NotificationOriginName.AEZ_ENB_SCHEDULED_PICKUP;
    case 'ENB_DAY_OF_PICKUP':
      return NotificationOriginName.ENB_DAY_OF_PICKUP;
    case 'AEZ_ENB_CONFIRMED_PICKUP':
      return NotificationOriginName.AEZ_ENB_CONFIRMED_PICKUP;
    case 'AEZ_ENB_RESCHEDULED_PICKUP':
      return NotificationOriginName.AEZ_ENB_RESCHEDULED_PICKUP;
    case 'ENB_REMINDER_TO_UPLOAD_DE_NT':
      return NotificationOriginName.ENB_REMINDER_TO_UPLOAD_DE_NT;
    case 'ENB_REMINDER_TO_UPLOAD_SU_NT':
      return NotificationOriginName.ENB_REMINDER_TO_UPLOAD_SU_NT;
    case 'AEZ_ENB_HAS_UPLOADED_DE_NT':
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_DE_NT;
    case 'AEZ_ENB_HAS_UPLOADED_SU_NT':
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_SU_NT;
    case 'ENB_REMINDER_TO_UPLOAD_WE_NT':
      return NotificationOriginName.ENB_REMINDER_TO_UPLOAD_WE_NT;
    case 'AEZ_ENB_HAS_UPLOADED_WE_NT':
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_WE_NT;
    case 'ENB_REMINDER_TO_UPLOAD_INV':
      return NotificationOriginName.ENB_REMINDER_TO_UPLOAD_INV;
    case 'AEZ_ENB_HAS_UPLOADED_INV':
      return NotificationOriginName.AEZ_ENB_HAS_UPLOADED_INV;
    default:
      return null;
  }
}
